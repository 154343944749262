.schedule-grid-container {
  background-color: #fff;
  width: 1420px;
}

.schedule-grid-table {
  border-collapse: collapse;
  width: 100%;
}

.schedule-grid-table th, .schedule-grid-table td {
  text-align: center;
  border: 1px solid #ccc;
  padding: 2px;
}

.hour-label {
  width: 4px;
  font-size: 15px;
  font-weight: 500;
}

.day-label {
  pointer-events: none;
  width: 25px;
  font-size: 15px;
  font-weight: 500;
}

.time-range-label {
  pointer-events: none;
  width: 180px;
  font-size: 12px;
}

.edit-label {
  width: 80px;
  font-size: 12px;
}

.dusk-to-dawn-label {
  width: 120px;
  font-size: 12px;
}

.inactive-tile {
  background-color: #fff;
  width: 5px;
  height: 15px;
}

.inactive-tile.active {
  background-color: #66bb6a;
}

.tile {
  cursor: pointer;
  background-color: #fff;
  width: 5px;
  height: 15px;
}

.tile.active {
  background-color: #66bb6a;
}

.tile.selected {
  background-color: gray;
}

.tile:hover {
  background-color: #d3d3d3;
}

[zwibbler] {
  border: 1px solid #000;
  flex-flow: column;
  height: 700px;
  display: flex;
}

[z-canvas] {
  flex: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

ul {
  margin-left: 30px;
  padding: 0;
  list-style: none;
}

ol {
  margin-left: 46px;
  padding: 0;
  list-style: lower-alpha;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-\[-25px\] {
  bottom: -25px;
}

.bottom-\[105px\] {
  bottom: 105px;
}

.bottom-\[140px\] {
  bottom: 140px;
}

.bottom-\[35px\] {
  bottom: 35px;
}

.bottom-\[5px\] {
  bottom: 5px;
}

.bottom-\[70px\] {
  bottom: 70px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-10 {
  left: 2.5rem;
}

.left-6 {
  left: 1.5rem;
}

.left-\[-15px\] {
  left: -15px;
}

.left-\[-35px\] {
  left: -35px;
}

.left-\[-40px\] {
  left: -40px;
}

.left-\[-80px\] {
  left: -80px;
}

.left-\[-85px\] {
  left: -85px;
}

.left-\[-8px\] {
  left: -8px;
}

.left-\[0px\] {
  left: 0;
}

.left-\[1008px\] {
  left: 1008px;
}

.left-\[1010px\] {
  left: 1010px;
}

.left-\[1015px\] {
  left: 1015px;
}

.left-\[1020px\] {
  left: 1020px;
}

.left-\[1035px\] {
  left: 1035px;
}

.left-\[1040px\] {
  left: 1040px;
}

.left-\[1045px\] {
  left: 1045px;
}

.left-\[1055px\] {
  left: 1055px;
}

.left-\[1065px\] {
  left: 1065px;
}

.left-\[1084px\] {
  left: 1084px;
}

.left-\[1090px\] {
  left: 1090px;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[110px\] {
  left: 110px;
}

.left-\[1115px\] {
  left: 1115px;
}

.left-\[1120px\] {
  left: 1120px;
}

.left-\[1130px\] {
  left: 1130px;
}

.left-\[1140px\] {
  left: 1140px;
}

.left-\[1145px\] {
  left: 1145px;
}

.left-\[1150px\] {
  left: 1150px;
}

.left-\[1165px\] {
  left: 1165px;
}

.left-\[1205px\] {
  left: 1205px;
}

.left-\[120px\] {
  left: 120px;
}

.left-\[1215px\] {
  left: 1215px;
}

.left-\[1225px\] {
  left: 1225px;
}

.left-\[1250px\] {
  left: 1250px;
}

.left-\[125px\] {
  left: 125px;
}

.left-\[1270px\] {
  left: 1270px;
}

.left-\[1290px\] {
  left: 1290px;
}

.left-\[12px\] {
  left: 12px;
}

.left-\[130px\] {
  left: 130px;
}

.left-\[135px\] {
  left: 135px;
}

.left-\[145px\] {
  left: 145px;
}

.left-\[150px\] {
  left: 150px;
}

.left-\[155px\] {
  left: 155px;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[160px\] {
  left: 160px;
}

.left-\[165px\] {
  left: 165px;
}

.left-\[170px\] {
  left: 170px;
}

.left-\[175px\] {
  left: 175px;
}

.left-\[185px\] {
  left: 185px;
}

.left-\[18px\] {
  left: 18px;
}

.left-\[190px\] {
  left: 190px;
}

.left-\[1px\] {
  left: 1px;
}

.left-\[200px\] {
  left: 200px;
}

.left-\[20px\] {
  left: 20px;
}

.left-\[210px\] {
  left: 210px;
}

.left-\[220px\] {
  left: 220px;
}

.left-\[225px\] {
  left: 225px;
}

.left-\[230\], .left-\[230px\] {
  left: 230px;
}

.left-\[233px\] {
  left: 233px;
}

.left-\[250px\] {
  left: 250px;
}

.left-\[255px\] {
  left: 255px;
}

.left-\[25px\] {
  left: 25px;
}

.left-\[280px\] {
  left: 280px;
}

.left-\[290px\] {
  left: 290px;
}

.left-\[300px\] {
  left: 300px;
}

.left-\[308px\] {
  left: 308px;
}

.left-\[30px\] {
  left: 30px;
}

.left-\[312px\] {
  left: 312px;
}

.left-\[31px\] {
  left: 31px;
}

.left-\[320px\] {
  left: 320px;
}

.left-\[325px\] {
  left: 325px;
}

.left-\[330px\] {
  left: 330px;
}

.left-\[345px\] {
  left: 345px;
}

.left-\[370px\] {
  left: 370px;
}

.left-\[373px\] {
  left: 373px;
}

.left-\[375px\] {
  left: 375px;
}

.left-\[380px\] {
  left: 380px;
}

.left-\[390px\] {
  left: 390px;
}

.left-\[393px\] {
  left: 393px;
}

.left-\[400px\] {
  left: 400px;
}

.left-\[405px\] {
  left: 405px;
}

.left-\[40px\] {
  left: 40px;
}

.left-\[420px\] {
  left: 420px;
}

.left-\[425px\] {
  left: 425px;
}

.left-\[43\.2\%\] {
  left: 43.2%;
}

.left-\[430px\] {
  left: 430px;
}

.left-\[435px\] {
  left: 435px;
}

.left-\[460px\] {
  left: 460px;
}

.left-\[468px\] {
  left: 468px;
}

.left-\[470px\] {
  left: 470px;
}

.left-\[475px\] {
  left: 475px;
}

.left-\[480px\] {
  left: 480px;
}

.left-\[485px\] {
  left: 485px;
}

.left-\[495px\] {
  left: 495px;
}

.left-\[4px\] {
  left: 4px;
}

.left-\[500px\] {
  left: 500px;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[516px\] {
  left: 516px;
}

.left-\[525px\] {
  left: 525px;
}

.left-\[530px\] {
  left: 530px;
}

.left-\[540px\] {
  left: 540px;
}

.left-\[555px\] {
  left: 555px;
}

.left-\[55px\] {
  left: 55px;
}

.left-\[560px\] {
  left: 560px;
}

.left-\[570px\] {
  left: 570px;
}

.left-\[575px\] {
  left: 575px;
}

.left-\[580px\] {
  left: 580px;
}

.left-\[585px\] {
  left: 585px;
}

.left-\[58px\] {
  left: 58px;
}

.left-\[590px\] {
  left: 590px;
}

.left-\[5px\] {
  left: 5px;
}

.left-\[600px\] {
  left: 600px;
}

.left-\[60px\] {
  left: 60px;
}

.left-\[620px\] {
  left: 620px;
}

.left-\[640px\] {
  left: 640px;
}

.left-\[65\%\] {
  left: 65%;
}

.left-\[650px\] {
  left: 650px;
}

.left-\[655px\] {
  left: 655px;
}

.left-\[660px\] {
  left: 660px;
}

.left-\[670px\] {
  left: 670px;
}

.left-\[67px\] {
  left: 67px;
}

.left-\[685px\] {
  left: 685px;
}

.left-\[690px\] {
  left: 690px;
}

.left-\[700px\] {
  left: 700px;
}

.left-\[70px\] {
  left: 70px;
}

.left-\[715px\] {
  left: 715px;
}

.left-\[72px\] {
  left: 72px;
}

.left-\[735px\] {
  left: 735px;
}

.left-\[740px\] {
  left: 740px;
}

.left-\[745px\] {
  left: 745px;
}

.left-\[747px\] {
  left: 747px;
}

.left-\[75px\] {
  left: 75px;
}

.left-\[77px\] {
  left: 77px;
}

.left-\[780px\] {
  left: 780px;
}

.left-\[800px\] {
  left: 800px;
}

.left-\[815px\] {
  left: 815px;
}

.left-\[822px\] {
  left: 822px;
}

.left-\[825px\] {
  left: 825px;
}

.left-\[830px\] {
  left: 830px;
}

.left-\[845px\] {
  left: 845px;
}

.left-\[850px\] {
  left: 850px;
}

.left-\[855px\] {
  left: 855px;
}

.left-\[85px\] {
  left: 85px;
}

.left-\[875px\] {
  left: 875px;
}

.left-\[878px\] {
  left: 878px;
}

.left-\[88\.5\%\] {
  left: 88.5%;
}

.left-\[882px\] {
  left: 882px;
}

.left-\[88px\] {
  left: 88px;
}

.left-\[8px\] {
  left: 8px;
}

.left-\[900px\] {
  left: 900px;
}

.left-\[905px\] {
  left: 905px;
}

.left-\[920px\] {
  left: 920px;
}

.left-\[935px\] {
  left: 935px;
}

.left-\[940px\] {
  left: 940px;
}

.left-\[945px\] {
  left: 945px;
}

.left-\[950px\] {
  left: 950px;
}

.left-\[959px\] {
  left: 959px;
}

.left-\[95px\] {
  left: 95px;
}

.left-\[960px\] {
  left: 960px;
}

.left-\[970px\] {
  left: 970px;
}

.left-\[980px\] {
  left: 980px;
}

.left-\[990px\] {
  left: 990px;
}

.right-0 {
  right: 0;
}

.right-12 {
  right: 3rem;
}

.right-24 {
  right: 6rem;
}

.right-32 {
  right: 8rem;
}

.right-4 {
  right: 1rem;
}

.right-44 {
  right: 11rem;
}

.right-52 {
  right: 13rem;
}

.right-6 {
  right: 1.5rem;
}

.right-9 {
  right: 2.25rem;
}

.right-\[-169px\] {
  right: -169px;
}

.right-\[-40px\] {
  right: -40px;
}

.right-\[0px\] {
  right: 0;
}

.right-\[1000px\] {
  right: 1000px;
}

.right-\[100px\] {
  right: 100px;
}

.right-\[1070px\] {
  right: 1070px;
}

.right-\[1300px\] {
  right: 1300px;
}

.right-\[130px\] {
  right: 130px;
}

.right-\[150\], .right-\[150px\] {
  right: 150px;
}

.right-\[15px\] {
  right: 15px;
}

.right-\[160px\] {
  right: 160px;
}

.right-\[170px\] {
  right: 170px;
}

.right-\[180px\] {
  right: 180px;
}

.right-\[190px\] {
  right: 190px;
}

.right-\[1px\] {
  right: 1px;
}

.right-\[200px\] {
  right: 200px;
}

.right-\[20px\] {
  right: 20px;
}

.right-\[215px\] {
  right: 215px;
}

.right-\[230px\] {
  right: 230px;
}

.right-\[244px\] {
  right: 244px;
}

.right-\[250px\] {
  right: 250px;
}

.right-\[25px\] {
  right: 25px;
}

.right-\[288px\] {
  right: 288px;
}

.right-\[30px\] {
  right: 30px;
}

.right-\[350px\] {
  right: 350px;
}

.right-\[35px\] {
  right: 35px;
}

.right-\[360px\] {
  right: 360px;
}

.right-\[40px\] {
  right: 40px;
}

.right-\[430px\] {
  right: 430px;
}

.right-\[442px\] {
  right: 442px;
}

.right-\[450px\] {
  right: 450px;
}

.right-\[485px\] {
  right: 485px;
}

.right-\[490px\] {
  right: 490px;
}

.right-\[505px\] {
  right: 505px;
}

.right-\[50px\] {
  right: 50px;
}

.right-\[520px\] {
  right: 520px;
}

.right-\[550px\] {
  right: 550px;
}

.right-\[5px\] {
  right: 5px;
}

.right-\[600px\] {
  right: 600px;
}

.right-\[655px\] {
  right: 655px;
}

.right-\[70px\] {
  right: 70px;
}

.right-\[75px\] {
  right: 75px;
}

.right-\[7vw\] {
  right: 7vw;
}

.right-\[815px\] {
  right: 815px;
}

.right-\[90px\] {
  right: 90px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-20 {
  top: 5rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-\[-48px\] {
  top: -48px;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[0px\] {
  top: 0;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[110px\] {
  top: 110px;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[12\%\] {
  top: 12%;
}

.top-\[120px\] {
  top: 120px;
}

.top-\[1270px\] {
  top: 1270px;
}

.top-\[12px\] {
  top: 12px;
}

.top-\[130px\] {
  top: 130px;
}

.top-\[135px\] {
  top: 135px;
}

.top-\[137px\] {
  top: 137px;
}

.top-\[142px\] {
  top: 142px;
}

.top-\[145px\] {
  top: 145px;
}

.top-\[150px\] {
  top: 150px;
}

.top-\[157px\] {
  top: 157px;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[160px\] {
  top: 160px;
}

.top-\[165px\] {
  top: 165px;
}

.top-\[167px\] {
  top: 167px;
}

.top-\[16px\] {
  top: 16px;
}

.top-\[170px\] {
  top: 170px;
}

.top-\[172px\] {
  top: 172px;
}

.top-\[17px\] {
  top: 17px;
}

.top-\[180px\] {
  top: 180px;
}

.top-\[18px\] {
  top: 18px;
}

.top-\[190px\] {
  top: 190px;
}

.top-\[195px\] {
  top: 195px;
}

.top-\[1px\] {
  top: 1px;
}

.top-\[200px\] {
  top: 200px;
}

.top-\[20px\] {
  top: 20px;
}

.top-\[215px\] {
  top: 215px;
}

.top-\[220px\] {
  top: 220px;
}

.top-\[225px\] {
  top: 225px;
}

.top-\[230px\] {
  top: 230px;
}

.top-\[235px\] {
  top: 235px;
}

.top-\[24\.5vh\] {
  top: 24.5vh;
}

.top-\[240px\] {
  top: 240px;
}

.top-\[245px\] {
  top: 245px;
}

.top-\[255px\] {
  top: 255px;
}

.top-\[25px\] {
  top: 25px;
}

.top-\[270\] {
  top: 270px;
}

.top-\[275px\] {
  top: 275px;
}

.top-\[276px\] {
  top: 276px;
}

.top-\[280px\] {
  top: 280px;
}

.top-\[285px\] {
  top: 285px;
}

.top-\[290px\] {
  top: 290px;
}

.top-\[301px\] {
  top: 301px;
}

.top-\[30px\] {
  top: 30px;
}

.top-\[310px\] {
  top: 310px;
}

.top-\[320px\] {
  top: 320px;
}

.top-\[325px\] {
  top: 325px;
}

.top-\[330px\] {
  top: 330px;
}

.top-\[342px\] {
  top: 342px;
}

.top-\[346px\] {
  top: 346px;
}

.top-\[355px\] {
  top: 355px;
}

.top-\[35px\] {
  top: 35px;
}

.top-\[360px\] {
  top: 360px;
}

.top-\[36px\] {
  top: 36px;
}

.top-\[380px\] {
  top: 380px;
}

.top-\[38px\] {
  top: 38px;
}

.top-\[390px\] {
  top: 390px;
}

.top-\[395px\] {
  top: 395px;
}

.top-\[400px\] {
  top: 400px;
}

.top-\[40px\] {
  top: 40px;
}

.top-\[410px\] {
  top: 410px;
}

.top-\[411px\] {
  top: 411px;
}

.top-\[427px\] {
  top: 427px;
}

.top-\[42px\] {
  top: 42px;
}

.top-\[44px\] {
  top: 44px;
}

.top-\[450px\] {
  top: 450px;
}

.top-\[460px\] {
  top: 460px;
}

.top-\[474px\] {
  top: 474px;
}

.top-\[47px\] {
  top: 47px;
}

.top-\[480px\] {
  top: 480px;
}

.top-\[495px\] {
  top: 495px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[52px\] {
  top: 52px;
}

.top-\[53px\] {
  top: 53px;
}

.top-\[557px\] {
  top: 557px;
}

.top-\[55px\] {
  top: 55px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[60px\] {
  top: 60px;
}

.top-\[617px\] {
  top: 617px;
}

.top-\[625px\] {
  top: 625px;
}

.top-\[65px\] {
  top: 65px;
}

.top-\[666px\] {
  top: 666px;
}

.top-\[68px\] {
  top: 68px;
}

.top-\[7\.5px\] {
  top: 7.5px;
}

.top-\[70px\] {
  top: 70px;
}

.top-\[75px\] {
  top: 75px;
}

.top-\[77px\] {
  top: 77px;
}

.top-\[7px\] {
  top: 7px;
}

.top-\[80px\] {
  top: 80px;
}

.top-\[870px\] {
  top: 870px;
}

.top-\[87px\] {
  top: 87px;
}

.top-\[885px\] {
  top: 885px;
}

.top-\[8px\] {
  top: 8px;
}

.top-\[90px\] {
  top: 90px;
}

.top-\[92px\] {
  top: 92px;
}

.top-\[95px\] {
  top: 95px;
}

.top-\[975px\] {
  top: 975px;
}

.top-\[97px\] {
  top: 97px;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.ml-\[150px\] {
  margin-left: 150px;
}

.ml-\[215px\] {
  margin-left: 215px;
}

.ml-\[75px\] {
  margin-left: 75px;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[200px\] {
  margin-top: 200px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[40px\] {
  margin-top: 40px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[165px\] {
  height: 165px;
}

.h-\[170px\] {
  height: 170px;
}

.h-\[17vh\] {
  height: 17vh;
}

.h-\[187px\] {
  height: 187px;
}

.h-\[188px\] {
  height: 188px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[208px\] {
  height: 208px;
}

.h-\[218px\] {
  height: 218px;
}

.h-\[235px\] {
  height: 235px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[260px\] {
  height: 260px;
}

.h-\[27\.5vh\] {
  height: 27.5vh;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[340px\] {
  height: 340px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[368px\] {
  height: 368px;
}

.h-\[370px\] {
  height: 370px;
}

.h-\[380px\] {
  height: 380px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[390px\] {
  height: 390px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[423px\] {
  height: 423px;
}

.h-\[425px\] {
  height: 425px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[453px\] {
  height: 453px;
}

.h-\[455px\] {
  height: 455px;
}

.h-\[460\] {
  height: 460px;
}

.h-\[473px\] {
  height: 473px;
}

.h-\[475px\] {
  height: 475px;
}

.h-\[480px\] {
  height: 480px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[510px\] {
  height: 510px;
}

.h-\[530px\] {
  height: 530px;
}

.h-\[540px\] {
  height: 540px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[560px\] {
  height: 560px;
}

.h-\[573px\] {
  height: 573px;
}

.h-\[575px\] {
  height: 575px;
}

.h-\[57px\] {
  height: 57px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[59px\] {
  height: 59px;
}

.h-\[5vh\] {
  height: 5vh;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[630px\] {
  height: 630px;
}

.h-\[650px\] {
  height: 650px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[92px\] {
  height: 92px;
}

.h-\[98px\] {
  height: 98px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[150px\] {
  min-height: 150px;
}

.min-h-\[187px\] {
  min-height: 187px;
}

.min-h-\[360px\] {
  min-height: 360px;
}

.min-h-\[57px\] {
  min-height: 57px;
}

.w-1\/2 {
  width: 50%;
}

.w-24 {
  width: 6rem;
}

.w-5 {
  width: 1.25rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[1090px\] {
  width: 1090px;
}

.w-\[1170px\] {
  width: 1170px;
}

.w-\[1200px\] {
  width: 1200px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[1230px\] {
  width: 1230px;
}

.w-\[1248px\] {
  width: 1248px;
}

.w-\[1250px\] {
  width: 1250px;
}

.w-\[1300px\] {
  width: 1300px;
}

.w-\[1305px\] {
  width: 1305px;
}

.w-\[1323px\] {
  width: 1323px;
}

.w-\[1325px\] {
  width: 1325px;
}

.w-\[1375px\] {
  width: 1375px;
}

.w-\[1380px\] {
  width: 1380px;
}

.w-\[1450\], .w-\[1450px\] {
  width: 1450px;
}

.w-\[1475px\] {
  width: 1475px;
}

.w-\[1500px\] {
  width: 1500px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[170px\] {
  width: 170px;
}

.w-\[174px\] {
  width: 174px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[248px\] {
  width: 248px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[275px\] {
  width: 275px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[305px\] {
  width: 305px;
}

.w-\[330px\] {
  width: 330px;
}

.w-\[333px\] {
  width: 333px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[413px\] {
  width: 413px;
}

.w-\[420px\] {
  width: 420px;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[520px\] {
  width: 520px;
}

.w-\[540px\] {
  width: 540px;
}

.w-\[550px\] {
  width: 550px;
}

.w-\[580px\] {
  width: 580px;
}

.w-\[590px\] {
  width: 590px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[608px\] {
  width: 608px;
}

.w-\[610px\] {
  width: 610px;
}

.w-\[615px\] {
  width: 615px;
}

.w-\[617px\] {
  width: 617px;
}

.w-\[618px\] {
  width: 618px;
}

.w-\[620px\] {
  width: 620px;
}

.w-\[625px\] {
  width: 625px;
}

.w-\[640px\] {
  width: 640px;
}

.w-\[645px\] {
  width: 645px;
}

.w-\[646px\] {
  width: 646px;
}

.w-\[647px\] {
  width: 647px;
}

.w-\[648px\] {
  width: 648px;
}

.w-\[650px\] {
  width: 650px;
}

.w-\[652px\] {
  width: 652px;
}

.w-\[654px\] {
  width: 654px;
}

.w-\[655px\] {
  width: 655px;
}

.w-\[656px\] {
  width: 656px;
}

.w-\[660px\] {
  width: 660px;
}

.w-\[680px\] {
  width: 680px;
}

.w-\[722px\] {
  width: 722px;
}

.w-\[725px\] {
  width: 725px;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[775px\] {
  width: 775px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[870px\] {
  width: 870px;
}

.w-\[88vw\] {
  width: 88vw;
}

.w-\[895\] {
  width: 895px;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[1000px\] {
  min-width: 1000px;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[1150px\] {
  min-width: 1150px;
}

.min-w-\[1200px\] {
  min-width: 1200px;
}

.min-w-\[1250px\] {
  min-width: 1250px;
}

.min-w-\[1350px\] {
  min-width: 1350px;
}

.min-w-\[1370px\] {
  min-width: 1370px;
}

.min-w-\[1450px\] {
  min-width: 1450px;
}

.min-w-\[470px\] {
  min-width: 470px;
}

.min-w-\[500px\] {
  min-width: 500px;
}

.min-w-\[600px\] {
  min-width: 600px;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[-140px\] {
  --tw-translate-x: 140px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.columns-2 {
  columns: 2;
}

.columns-5 {
  columns: 5;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-\[\#2E7D32\] {
  --tw-border-opacity: 1;
  border-color: rgb(46 125 50 / var(--tw-border-opacity, 1));
}

.border-\[\#7d2e72\] {
  --tw-border-opacity: 1;
  border-color: rgb(125 46 114 / var(--tw-border-opacity, 1));
}

.border-\[\#D32F2F\] {
  --tw-border-opacity: 1;
  border-color: rgb(211 47 47 / var(--tw-border-opacity, 1));
}

.border-actuate-grey {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}

.border-actuate-orange {
  --tw-border-opacity: 1;
  border-color: rgb(255 132 0 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.bg-\[\#2E7D32\] {
  --tw-bg-opacity: 1;
  background-color: rgb(46 125 50 / var(--tw-bg-opacity, 1));
}

.bg-\[\#7d2e72\] {
  --tw-bg-opacity: 1;
  background-color: rgb(125 46 114 / var(--tw-bg-opacity, 1));
}

.bg-\[\#A9A9A9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(169 169 169 / var(--tw-bg-opacity, 1));
}

.bg-\[\#BDBDBD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(189 189 189 / var(--tw-bg-opacity, 1));
}

.bg-\[\#D32F2F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(211 47 47 / var(--tw-bg-opacity, 1));
}

.bg-actuate-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 25 67 / var(--tw-bg-opacity, 1));
}

.bg-actuate-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}

.bg-actuate-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(255 132 0 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.p-1\.5 {
  padding: .375rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[5px\] {
  padding: 5px;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pb-\[20px\] {
  padding-bottom: 20px;
}

.pb-\[2px\] {
  padding-bottom: 2px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pb-\[px\] {
  padding-bottom: px;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-\[100px\] {
  padding-left: 100px;
}

.pl-\[105px\] {
  padding-left: 105px;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pl-\[13px\] {
  padding-left: 13px;
}

.pl-\[140px\] {
  padding-left: 140px;
}

.pl-\[15px\] {
  padding-left: 15px;
}

.pl-\[170px\] {
  padding-left: 170px;
}

.pl-\[190px\] {
  padding-left: 190px;
}

.pl-\[20px\] {
  padding-left: 20px;
}

.pl-\[240px\] {
  padding-left: 240px;
}

.pl-\[25px\] {
  padding-left: 25px;
}

.pl-\[30px\] {
  padding-left: 30px;
}

.pl-\[33px\] {
  padding-left: 33px;
}

.pl-\[350px\] {
  padding-left: 350px;
}

.pl-\[35px\] {
  padding-left: 35px;
}

.pl-\[40px\] {
  padding-left: 40px;
}

.pl-\[420px\] {
  padding-left: 420px;
}

.pl-\[520px\] {
  padding-left: 520px;
}

.pl-\[5px\] {
  padding-left: 5px;
}

.pl-\[6px\] {
  padding-left: 6px;
}

.pl-\[7px\] {
  padding-left: 7px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pl-\[95px\] {
  padding-left: 95px;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.pr-\[20px\] {
  padding-right: 20px;
}

.pr-\[35px\] {
  padding-right: 35px;
}

.pr-\[40px\] {
  padding-right: 40px;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[10px\] {
  padding-top: 10px;
}

.pt-\[120px\] {
  padding-top: 120px;
}

.pt-\[13px\] {
  padding-top: 13px;
}

.pt-\[140px\] {
  padding-top: 140px;
}

.pt-\[15px\] {
  padding-top: 15px;
}

.pt-\[1px\] {
  padding-top: 1px;
}

.pt-\[200px\] {
  padding-top: 200px;
}

.pt-\[20px\] {
  padding-top: 20px;
}

.pt-\[30px\] {
  padding-top: 30px;
}

.pt-\[3px\] {
  padding-top: 3px;
}

.pt-\[40px\] {
  padding-top: 40px;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[7px\] {
  padding-top: 7px;
}

.pt-\[8px\] {
  padding-top: 8px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.indent-3 {
  text-indent: .75rem;
}

.indent-4 {
  text-indent: 1rem;
}

.indent-5 {
  text-indent: 1.25rem;
}

.indent-6 {
  text-indent: 1.5rem;
}

.indent-\[29px\] {
  text-indent: 29px;
}

.align-middle {
  vertical-align: middle;
}

.font-mulish {
  font-family: Mulish, sans-serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14\.5px\] {
  font-size: 14.5px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[80px\] {
  font-size: 80px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.text-\[\#0000EE\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 238 / var(--tw-text-opacity, 1));
}

.text-\[\#182E54\] {
  --tw-text-opacity: 1;
  color: rgb(24 46 84 / var(--tw-text-opacity, 1));
}

.text-\[\#283E6D\] {
  --tw-text-opacity: 1;
  color: rgb(40 62 109 / var(--tw-text-opacity, 1));
}

.text-\[\#2E7D32\] {
  --tw-text-opacity: 1;
  color: rgb(46 125 50 / var(--tw-text-opacity, 1));
}

.text-\[\#757575\] {
  --tw-text-opacity: 1;
  color: rgb(117 117 117 / var(--tw-text-opacity, 1));
}

.text-\[\#7d2e72\] {
  --tw-text-opacity: 1;
  color: rgb(125 46 114 / var(--tw-text-opacity, 1));
}

.text-\[\#D32F2F\] {
  --tw-text-opacity: 1;
  color: rgb(211 47 47 / var(--tw-text-opacity, 1));
}

.text-\[\#F44336\] {
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity, 1));
}

.text-\[\#F57C00\] {
  --tw-text-opacity: 1;
  color: rgb(245 124 0 / var(--tw-text-opacity, 1));
}

.text-\[\#FF0000\] {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity, 1));
}

.text-actuate-blue {
  --tw-text-opacity: 1;
  color: rgb(0 25 67 / var(--tw-text-opacity, 1));
}

.text-actuate-orange {
  --tw-text-opacity: 1;
  color: rgb(255 132 0 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-1 {
  text-underline-offset: 1px;
}

.opacity-10 {
  opacity: .1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-button {
  --tw-shadow: 0px 3px 1px -2px #0003, 0px 2px 2px #00000024, 0px 1px 5px #0000001f;
  --tw-shadow-colored: 0px 3px 1px -2px var(--tw-shadow-color), 0px 2px 2px var(--tw-shadow-color), 0px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.lds-facebook {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-facebook div {
  background: #ff8400;
  width: 16px;
  animation: 1.2s cubic-bezier(0, .5, .5, 1) infinite lds-facebook;
  display: inline-block;
  position: absolute;
  left: 8px;
}

.lds-facebook div:first-child {
  animation-delay: -.24s;
  left: 8px;
}

.lds-facebook div:nth-child(2) {
  animation-delay: -.12s;
  left: 32px;
}

.lds-facebook div:nth-child(3) {
  animation-delay: 0;
  left: 56px;
}

@keyframes lds-facebook {
  0% {
    height: 64px;
    top: 8px;
  }

  50%, 100% {
    height: 32px;
    top: 24px;
  }
}

.pc-scrollbars .scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.pc-scrollbars .scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.first-line\:font-black:first-line {
  font-weight: 900;
}

.hover\:bg-\[\#142D57\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 45 87 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#E67300\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 115 0 / var(--tw-bg-opacity, 1));
}

.hover\:text-actuate-orange:hover {
  --tw-text-opacity: 1;
  color: rgb(255 132 0 / var(--tw-text-opacity, 1));
}

@media (width >= 768px) {
  .md\:right-\[20px\] {
    right: 20px;
  }

  .md\:top-\[20px\] {
    top: 20px;
  }
}
/*# sourceMappingURL=index.b5320809.css.map */
