[zwibbler] {
    display: flex;
    flex-flow: column nowrap;
    height: 700px;
    border: 1px solid black;
  }
  
  [z-canvas] {
    flex: 1 1 auto;
  }
  