.schedule-grid-container {
  width: 1420px;
  background-color: white;
}

.schedule-grid-table {
  border-collapse: collapse;
  width: 100%;
}

.schedule-grid-table th,
.schedule-grid-table td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 2px;
}

.hour-label {
  width: 4px;
  /* Adjust width as needed */
  font-size: 15px;
  font-weight: 500;
  /* Adjust font size as needed */
}

.day-label {
  width: 25px;
  /* Adjust width as needed */
  font-size: 15px;
  font-weight: 500;
  pointer-events: none;
  /* Adjust font size as needed */
}

.time-range-label {
  width: 180px;
  /* Adjust width as needed */
  font-size: 12px;
  /* Adjust font size as needed */
  pointer-events: none;
}

.edit-label {
  width: 80px;
  /* Adjust width as needed */
  font-size: 12px;
  /* Adjust font size as needed */
}

.dusk-to-dawn-label {
  width: 120px;
  /* Adjust width as needed */
  font-size: 12px;
  /* Adjust font size as needed */
}

.inactive-tile {
width: 5px;
  /* Adjust width as needed */
  height: 15px;
  /* Adjust height as needed */
  background-color: white;
}

.inactive-tile.active {
  background-color: #66BB6A;
}

.tile {
  width: 5px;
  /* Adjust width as needed */
  height: 15px;
  /* Adjust height as needed */
  background-color: white;
  cursor: pointer;
  /* Indicates that the element is interactive */
}

.tile.active {
  background-color: #66BB6A;
}

.tile.selected {
  background-color: grey;
}

.tile:hover {
  background-color: lightgray;
  /* Hover color */
}