@tailwind base;
@tailwind components;
@tailwind utilities;

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #FF8400;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  @layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    ul {
      list-style: none;
      margin-left: 30px;
      padding: 0;
    }
    /* li {
      margin: 10px 0
    } */
    ol {
      list-style: lower-alpha;
      margin-left: 46px;
      padding: 0;
    }
  }
.pc-scrollbars .scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.pc-scrollbars .scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE and Edge */
}

  

  